import { SafeArea } from 'capacitor-plugin-safe-area'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { InsetTop } from './InsetTop'

function Header() {
  const [insetTop, setInsetTop] = useState(0)
  useEffect(() => {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      setInsetTop(insets.top)
    })
  }, [])
  return (
    <>
      <header>
        <InsetTop />
        <h1>Korbball App</h1>
        <div
          className="menu"
          style={{ top: `${insetTop + 10}px`, right: '1em' }}
        >
          <Link to={'/'}>Alle Ligen</Link>
        </div>
      </header>
      <InsetTop />
    </>
  )
}
export default Header
