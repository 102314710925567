import { SafeArea } from 'capacitor-plugin-safe-area'
import { useEffect, useState } from 'react'

export const InsetTop = () => {
  const [insetTop, setInsetTop] = useState(0)
  useEffect(() => {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      setInsetTop(insets.top)
    })
  }, [])
  return <div style={{ paddingTop: `${insetTop}px` }}></div>
}
